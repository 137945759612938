<template>
  <div class="containner" :class="is_mobile ? 'm-containner' : 'pc-containner'">
    <div class="banner">
      <img class="banner-bg an-banner-bg" :src="is_mobile ? banner_m : banner" />
      <div class="pro-series-content">
        <div class="pro-series-name an-pro-series-name">{{ $t("pro.razordcx.name") }}</div>
        <div class="pro-series-desc an-pro-series-desc">{{ $t("pro.razordcx.description") }}</div>
      </div>
    </div>
    <div class="performance-wrap">
      <img class="perform-bg" :src="is_mobile ? perform_bg_m : perform_bg" />
      <div class="perform-box">
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[0].icon" />
          <div class="perform-tit">{{ this.$t("pro.razordcx.perform_a") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[1].icon" />
          <div class="perform-tit">{{ this.$t("pro.razordcx.perform_b") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[2].icon" />
          <div class="perform-tit">{{ this.$t("pro.razordcx.perform_c") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[3].icon" />
          <div class="perform-tit">{{ this.$t("pro.razordcx.perform_d") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[4].icon" />
          <div class="perform-tit">{{ this.$t("pro.razordcx.perform_e") }}</div>
        </div>
      </div>
    </div>
    <div class="series-case">
      <img class="series-case-img" :src="series_case" />
      <div class="series-case-info">
        <div class="pro-series-name">{{ $t("pro.razordcx.pro_name") }}</div>
        <div class="pro-series-desc">{{ $t("pro.razordcx.pro_desc") }}</div>
        <div class="pro-series-btn"><router-link to="/pro/razorDCX-takla">{{ $t("pro.razordcx.pro_btn") }}</router-link></div>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from "gsap";

export default {
  name: "Razordcx",
  props: {},
  data() {
    return {
      banner: require("@/assets/series-razordcx-banner.png"),
      banner_m: require("@/assets/series-razordcx-banner-m.png"),
      series_case: require("@/assets/series-razordcx-takla.png"),
      perform_bg: require("@/assets/i-bg-a.png"),
      perform_bg_m: require("@/assets/series-bg.png"),
      icon_a: require("@/assets/rdx-icon-1.png"),
      icon_b: require("@/assets/rdx-icon-2.png"),
      icon_c: require("@/assets/rdx-icon-4.png"),
      icon_d: require("@/assets/rdx-icon-5.png"),

      perform_list: [
        {
          icon: require("@/assets/rdx-icon-1.png"),
        },
        {
          icon: require("@/assets/rdx-icon-2.png"),
        },
        {
          icon: require("@/assets/rdx-icon-3.png"),
        },
        {
          icon: require("@/assets/rdx-icon-4.png"),
        },
        {
          icon: require("@/assets/rdx-icon-5.png"),
        },
      ],
    };
  },
  methods: {},
  components: {},
  mounted() {
    const tl = gsap.timeline({});
    tl.addLabel("start")
      .from(".an-banner-bg", {
        opacity: 0,
        x: 100,
      })
      .from(".an-pro-series-name", {
        opacity: 0,
        y: 50,
      })
      .from(".an-pro-series-desc", {
        opacity: 0,
        y: 50,
      })
  },
};
</script>
<style lang="less" scoped>
.containner {
  position: relative;
  margin-top: 100px;
}
.banner {
  position: relative;
  height: 587px;
}
.banner-bg {
  position: absolute;
  width: 1920px;
  height: 587px;
  z-index: 0;
  left: 0px;
  top: 0px;
}
.pro-series-content {
  position: absolute;
  color: #fff;
  z-index: 11;
  top: 173px;
  left: 230px;
}
.pro-series-name {
  font-size: 44px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 29px;
}
.pro-series-desc {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  width: 529px;
  text-align: left;
}
.performance-wrap {
  position: relative;
  height: 530px;
  overflow: hidden;
}
.perform-bg {
  position: absolute;
  z-index: 0;
  left: 0px;
  bottom: 0px;
  width: 1920px;
  height: 709px;
}
.perform-box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 11;
}
.perform-list {
  margin-top: 152px;
  padding: 0px 50px;
}
.perform-icon {
  width: 101px;
  height: 101px;
}
.perform-tit {
  text-align: center;
  width: 184px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  margin-top: 21px;
}
.series-case {
  height: 540px;
  background: rgba(20, 20, 20, 1);
}
.series-case-img {
  width: 960px;
  height: 540px;
  float: right;
}
.series-case-info {
  position: relative;
  color: #ffffff;
  float: left;
  width: 575px;
  margin-left: 230px;
  margin-top: 133px;
  z-index: 11;
}
.pro-series-btn {
  background: #90dafc;
  width: 230px;
  height: 35px;
  border-radius: 5px;
  line-height: 35px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  margin-top: 70px;
  a{
    color: rgba(0, 0, 0, 1);
    display: block;
    user-select: none;
  }
}

.m-containner {
  .banner {
    position: relative;
    height: auto;
    overflow: hidden;
    height: 1370px;
    .banner-bg {
      width: 750px;
      height: 1370px;
    }
    .pro-series-content {
      width: 100%;
      text-align: center;
      left: 0px;
      .pro-series-name {
        width: 620px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
      .pro-series-desc {
        width: 620px;
        margin: 0 auto;
      }
    }
  }
  .performance-wrap {
    width: 750px;
    overflow: hidden;
    height: 634px;
    padding-top:40px;
    .perform-bg {
      width: 750px;
      height: 674px;
    }
    .perform-box {
      .perform-list {
        padding:10px;
        margin-top:30px;
      }
    }
  }
  .series-case{
    height: auto;
    .series-case-img{
      width: 750px;
    }
    .series-case-info{
      width: 420px;
      margin:0 auto;
      float: none;
      padding:70px 0px;
      overflow: hidden;
      .pro-series-name{
        width: 100%;
        text-align: center;
      }
      .pro-series-desc{
        width: 100%;
        text-align: center;
      }
      .pro-series-btn{
        width: 340px;
        height: 70px;
        line-height: 70px;
        font-size: 25px;
        margin:20px auto;
      }
    }
  }
}
</style>
